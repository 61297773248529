import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LocationData, VehicleTrackingData } from 'src/types';
import { emojiForImpactLevel, ImpactLevel, RouteData, wordForImpactLevel } from 'src/types/routes';
import { AssetGroupType, getAssetImpactLevel, getAssetName } from '.';
import { capitalize } from 'lodash';
import { Box, Typography } from '@mui/material';
import { AssetOption } from './SuperSearchOverlay';
import { getSubtitleForVehicle } from './VehicleTimelineView';
import { findMatchingVehicleForRoute } from '../Dashboard/data';

interface Props {
  selectedAssetType: AssetGroupType;
  onAssetOptionSelected: (option: AssetOption) => void;
  locations: LocationData[];
  vehicles: VehicleTrackingData[];
  routes: RouteData[];
  timeframeIndex: number;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
}

const getSubtitle = (option: AssetOption, timeframeIndex: number) => {
  const impactLevel = getAssetImpactLevel(option.model, timeframeIndex);
  switch (option.type) {
    case 'location':
      const emoji = emojiForImpactLevel(impactLevel);
      const word = wordForImpactLevel(impactLevel, true);
      return `${emoji} ${word} Impact`;
    case 'vehicle':
      const vehicle = option.model as VehicleTrackingData;
      let subtitle = getSubtitleForVehicle(vehicle);
      if (impactLevel !== ImpactLevel.Unknown) {
        const emoji = emojiForImpactLevel(impactLevel);
        const word = wordForImpactLevel(impactLevel, true);
        return `${subtitle} ∙ ${emoji} ${word} Impact`;
      } else {
        return subtitle;
      }
    case 'route':
      const route = option.model as RouteData;

      // put departure time
      let s = '';
      s += route.departureTime < new Date() ? 'Departed ' : 'Departing ';
      s += route.departureTime.toLocaleDateString([], { month: 'short', day: 'numeric' }) + ' ';
      s += route.departureTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      if (impactLevel !== ImpactLevel.Unknown) {
        const emoji = emojiForImpactLevel(impactLevel);
        const word = wordForImpactLevel(impactLevel, true);
        s += ` ∙ ${emoji} ${word} Impact`;
      }
      return s;
    default:
      return '';
  }
};

const mapToAssetOptions = (locations: LocationData[], vehicles: VehicleTrackingData[], routes: RouteData[]): AssetOption[] => {
  return [
    ...locations.map(location => ({ type: 'location', name: getAssetName(location), model: location, key: `location_${location.id}`, itemsToSearch: [location.name].filterNotNullOrUndefined() })),
    ...vehicles.map(vehicle => ({ type: 'vehicle', name: getAssetName(vehicle), model: vehicle, key: `vehicle_${vehicle.id}`, itemsToSearch: [vehicle.name, vehicle.externalId, ...vehicle.externalGroupIds].filterNotNullOrUndefined() })),
    ...routes.map(route => {
      const matchingVehicle = findMatchingVehicleForRoute(route, routes, vehicles);
      return { type: 'route', name: getAssetName(route), model: route, key: `route_${route.id}`, itemsToSearch: [route.name, route.origin.label, route.destination.label, route.externalId, matchingVehicle?.name, matchingVehicle?.externalId, ...(matchingVehicle?.externalGroupIds ?? [])].filterNotNullOrUndefined() };
    }),
  ] as AssetOption[];
};

export const SuperSearchBar = ({ locations, vehicles, routes, selectedAssetType, inputRef, timeframeIndex, onAssetOptionSelected }: Props) => {
  const allOptions = React.useMemo(() => {
    return mapToAssetOptions(locations, vehicles, routes);
  }, [locations, vehicles, routes]);
  const [inputValue, setInputValue] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState<AssetOption | null>(null);

  return (
    <Autocomplete
      options={allOptions}
      groupBy={(option) => capitalize(option.type) + 's'}
      filterOptions={(options, { inputValue }) => {
        if (!inputValue) return [];

        const filteredOptions = options.filter(option =>
          option.itemsToSearch.some((x) => x.toLowerCase().includes(inputValue.toLowerCase()))
        );

        const groupedOptions: Record<string, AssetOption[]> = { location: [], vehicle: [], route: [] };
        const typeCount: Record<string, number> = { location: 0, vehicle: 0, route: 0 };

        filteredOptions.forEach(option => {
          if (typeCount[option.type] < 10) {
            groupedOptions[option.type].push(option);
            typeCount[option.type] += 1;
          }
        });

        const flattenedOptions: AssetOption[] = [];
        let groupsOrder: string[] = [];
        if (selectedAssetType === 'location') {
          groupsOrder = ['location', 'vehicle', 'route'];
        } else if (selectedAssetType === 'vehicle') {
          groupsOrder = ['vehicle', 'location', 'route'];
        } else if (selectedAssetType === 'route') {
          groupsOrder = ['route', 'location', 'vehicle'];
        }

        groupsOrder.forEach(group => {
          if (groupedOptions[group].length > 0) {
            flattenedOptions.push(...groupedOptions[group]);
          }
        });

        return flattenedOptions;
      }}
      noOptionsText={inputValue ? "No matching results" : "Start typing"}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputValue ? "Jump to asset" : "Search for a location, vehicle, or route"}
          inputRef={inputRef}
        />
      )}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.key}
      renderOption={(props, option) => (
        <Box component="li" {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography variant="body1" sx={{ marginBottom: '2px' }}>{option.name}</Typography>
          <Typography variant="body2" color="textSecondary">{getSubtitle(option, timeframeIndex)}</Typography>
        </Box>
      )}
      value={selectedOption ?? undefined} // Fixed null assignment issue
      onChange={(event, value) => {
        setSelectedOption(null);
        setInputValue('');
        if (value) {
          onAssetOptionSelected(value);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== 'reset') {
          setInputValue(newInputValue);
        }
      }}
      popupIcon={null}
      disableClearable
      componentsProps={{
        clearIndicator: { sx: { display: "none" } } // Fixed incorrect property usage
      }}
    />
  );
};