import * as React from 'react';
import { cardBackgroundColor, } from 'src/constants';
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, IconButton, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { Close, DriveEtaOutlined, FilterList, LayersOutlined, List, RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined, RoomOutlined, RouteOutlined, SearchOutlined } from '@mui/icons-material';
import { AssetGroupType, AssetType, getAssetImpactLevel, getAssetName } from '.';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { AssetFilter } from 'src/types/AssetFilter';
import { SingleFilterSelector } from './SingleFilterSelector';
import { ThreeDot } from 'react-loading-indicators';
import { ExportIcon } from '../Dashboard/ImpactDetailView';
import { ClientId, isLatLngInViewport, isValidUserForFeature, LocationData, UserState, VehicleTrackingData, ViewportData } from 'src/types';
import { darkTagColorForImpactLevel, findImpactLevel, ImpactLevel, RouteData, wordForImpactLevel } from 'src/types/routes';
import { exportCSVFile } from 'src/util';
import { formatRatingKey } from 'src/types/RatingKey';

const menuHeadings = {
    mapLayers: 'Map Layers',
    assetTypes: 'My Assets',
    superSearch: 'Find Assets',
};

interface NowcastMenuProps {
    userData: UserState;
    selectedTimeframeIndex: number;

    tilingLayer?: string;
    setTilingLayer: (layer?: string) => void;
    isGraphExpanded: boolean;
    itemCounts: any;
    hasEventsPermission: boolean;
    assetAlerts: string[];

    isAlertsVisible: boolean;
    isCyclonesVisible: boolean;
    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isPowerOutagesVisible: boolean;
    isStormsVisible: boolean;
    isStormReportsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isWildfiresVisible: boolean;
    isFloodChanceVisible: boolean;

    onAlertsToggled: (show: boolean) => void;
    onCyclonesToggled: (show: boolean) => void;
    onEarthquakesToggled: (show: boolean) => void;
    onLightningToggled: (show: boolean) => void;
    onPowerOutagesToggled: (show: boolean) => void;
    onStormsToggled: (show: boolean) => void;
    onStormReportsToggled: (show: boolean) => void;
    onVolcanoesToggled: (show: boolean) => void;
    onFiresToggled: (show: boolean) => void;
    onWildfiresToggled: (show: boolean) => void;
    onFloodChanceToggled: (show: boolean) => void;

    trafficLayer?: string;
    onTrafficLayerSelected: (layer: string | undefined) => void;

    selectedAssetGroup: AssetGroupType;
    setSelectedAssetGroup: (assetTypeGroup: AssetGroupType) => void;

    locations: LocationData[];
    isLocationsLoading: boolean;
    locationAssetTypeOptions: AssetType[];
    locationSelectedAssetTypes: AssetType[];
    locationCountsByAssetCategory: Record<string, number>;
    onLocationSelectedTypesChanged: (assetTypes: AssetType[]) => void;

    vehicles: VehicleTrackingData[];
    isVehiclesLoading: boolean;
    vehicleAssetTypeOptions: AssetType[];
    vehicleSelectedAssetTypes: AssetType[];
    vehicleCountsByAssetCategory: Record<string, number>;
    onVehicleSelectedTypesChanged: (assetTypes: AssetType[]) => void;

    shipments: RouteData[];
    isShipmentsLoading: boolean;
    shipmentAssetTypeOptions: AssetType[];
    shipmentSelectedAssetTypes: AssetType[];
    shipmentCountsByAssetCategory: Record<string, number>;
    onShipmentSelectedTypesChanged: (assetTypes: AssetType[]) => void;

    onAssetSelected: (asset: LocationData | VehicleTrackingData | RouteData) => void;
    mapViewport?: ViewportData;

    filters: AssetFilter[];
    onFiltersChanged: (filters: AssetFilter[]) => void;

    onSuperSearchPressed: () => void;
}

const MenuCategoryButtons = (props: { category: string, selectedAssetGroup: AssetGroupType, onCategorySelected: (category: string) => void, onSuperSearchPressed: () => void }) => {
    return (
        <>
            <header style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>Map Options</Typography>
            </header>
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 12, paddingBottom: 12 }}>
                <Button
                    variant={props.category === 'risk-layers' ? 'contained' : 'outlined'}
                    color={'primary'}
                    size={'small'}
                    style={{ marginBottom: 12, paddingLeft: 16, paddingRight: 16 }}

                    onClick={() => props.category === 'risk-layers' ? props.onCategorySelected('top-menu') : props.onCategorySelected('risk-layers')}
                    href="javascript:;"
                    className="NowcastMenu-MapLayersButton"
                >
                    &nbsp;
                    <LayersOutlined style={{ marginRight: '5px' }} fontSize={'small'} />
                    {menuHeadings.mapLayers}
                </Button>

                <Button
                    variant={(props.category === 'asset-types' || props.category.startsWith('filter')) ? 'contained' : 'outlined'}
                    color={'primary'}
                    size={'small'}
                    style={{ marginBottom: 12, paddingLeft: 16, paddingRight: 16 }}

                    onClick={() => props.category === 'asset-types' ? props.onCategorySelected('top-menu') : props.onCategorySelected('asset-types')}
                    href="javascript:;"
                    className="NowcastMenu-AssetsButton"
                >
                    &nbsp;
                    {props.selectedAssetGroup === 'location' ? <RoomOutlined style={{ marginRight: '5px' }} fontSize={'small'} />
                        : props.selectedAssetGroup === 'vehicle' ? <DriveEtaOutlined style={{ marginRight: '5px' }} fontSize={'small'} />
                            : <RouteOutlined style={{ marginRight: '5px' }} fontSize={'small'} />}
                    {menuHeadings.assetTypes}
                </Button>

                <Button
                    variant={props.category === 'filters' ? 'contained' : 'outlined'}
                    color={'primary'}
                    size={'small'}
                    style={{ marginBottom: 12, paddingLeft: 16, paddingRight: 16 }}

                    onClick={() => props.onSuperSearchPressed()}
                    href="javascript:;"
                    className="NowcastMenu-SuperSearchButton"
                >
                    &nbsp;
                    <SearchOutlined style={{ marginRight: '5px' }} fontSize={'small'} />
                    {menuHeadings.superSearch}
                </Button>
            </div>
        </>
    );
};

export const EventLink = (props: { label: string, isChecked: boolean, onToggled: (checked: boolean) => void, itemCount: number | null | undefined, isDisabled: boolean }) => {
    return (
        <FormControlLabel
            className={'checkbox'}
            control={<Checkbox
                sx={{ my: -0.5 }}
                size="small"
                checked={props.isChecked}
                onChange={event => props.onToggled(event.target.checked)} />}
            label={<div style={{ display: 'flex' }}>
                <Typography variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
                    {props.label}

                    {' '}

                    {/* if number, show number in parentheses. if undefined, show loading state. if null, show nothing because no permissions */}
                    {props.itemCount !== null && (props.itemCount !== undefined ?
                        `(${props.itemCount})` :
                        <>
                            {'('}
                            <ThreeDot color={props.isDisabled ? 'text.disabled' : 'text.primary'} style={{ fontSize: 3, marginLeft: 2, marginRight: 2 }} />
                            {')'}
                        </>
                    )}
                </Typography>
                {props.isDisabled && <img style={{ marginLeft: 5 }} alt={'premium-feature'} src={'/images/premium_feature_gem.svg'} />}
            </div>}
        />

    );
};

function NowcastMenu(props: NowcastMenuProps) {
    const [menuCategory, setMenuCategory] = React.useState('top-menu');
    const [expandedItemIds, setExpandedItemIds] = React.useState<string[]>([]);
    const [selectedAssets, setSelectedAssets] = React.useState<LocationData[] | VehicleTrackingData[] | RouteData[]>([]);
    const [filterAssetsToMapViewport, setFilterAssetsToMapViewport] = React.useState<boolean>(false);
    const [showCriticalEventsUpsell, setShowCriticalEventsUpsell] = React.useState<boolean>(false);
    const [showRoadConditionsUpsell, setShowRoadConditionsUpsell] = React.useState<boolean>(false);

    React.useEffect(() => {
        // currently we are only allowing one one asset group to be selected at a time
        // when one is selected default to selecting all types and deselecting all types for groups that are not selected
        if (props.selectedAssetGroup === 'location') {
            props.onLocationSelectedTypesChanged(props.locationAssetTypeOptions);
            setSelectedAssets(props.locations);
        } else {
            props.onLocationSelectedTypesChanged([]);
        }

        if (props.selectedAssetGroup === 'vehicle') {
            props.onVehicleSelectedTypesChanged(props.vehicleAssetTypeOptions);
            setSelectedAssets(props.vehicles);
        } else {
            props.onVehicleSelectedTypesChanged([]);
        }

        if (props.selectedAssetGroup === 'route') {
            props.onShipmentSelectedTypesChanged(props.shipmentAssetTypeOptions);
            setSelectedAssets(props.shipments);
        } else {
            props.onShipmentSelectedTypesChanged([]);
        }
    }, [props.selectedAssetGroup]);

    React.useEffect(() => {
        if (props.selectedAssetGroup === 'location') {
            setSelectedAssets(props.locations);
        } else if (props.selectedAssetGroup === 'vehicle') {
            setSelectedAssets(props.vehicles);
        } else {
            setSelectedAssets(props.shipments);
        }
    }, [props.locations, props.vehicles, props.shipments]);

    React.useEffect(() => {
        if (filterAssetsToMapViewport && props.mapViewport) {
            const allAssets = props.selectedAssetGroup === 'location' ? props.locations : props.selectedAssetGroup === 'vehicle' ? props.vehicles : props.shipments;
            const filteredAssets: any[] = [];

            for (const asset of allAssets) {
                if (props.selectedAssetGroup === 'location') {
                    if (isLatLngInViewport({ lat: (asset as LocationData).latitude, lng: (asset as LocationData).longitude }, props.mapViewport)) {
                        filteredAssets.push(asset);
                    }
                } else if (props.selectedAssetGroup === 'vehicle') {
                    if (isLatLngInViewport({ lat: (asset as VehicleTrackingData).latitude!, lng: (asset as VehicleTrackingData).longitude! }, props.mapViewport)) {
                        filteredAssets.push(asset);
                    }
                }
                // not supporting currently in view filtr for routes for now
                // } else {
                //     if (isLatLngInViewport({ lat: (asset as RouteData).origin.latitude, lng: (asset as RouteData).origin.longitude }, props.mapViewport) &&
                //         isLatLngInViewport({ lat: (asset as RouteData).destination.latitude, lng: (asset as RouteData).destination.longitude }, props.mapViewport)) {
                //         filteredAssets.push(asset);
                //     }
                // }
            }

            setSelectedAssets(filteredAssets);
        } else {
            if (props.selectedAssetGroup === 'location') {
                setSelectedAssets(props.locations);
            } else if (props.selectedAssetGroup === 'vehicle') {
                setSelectedAssets(props.vehicles);
            } else {
                setSelectedAssets(props.shipments);
            }
        }
    }, [filterAssetsToMapViewport, props.mapViewport]);

    const mapLayerOptions: JSX.Element = (
        <div>
            <div style={{ padding: '10px, 0px' }}>
                <div style={{ width: '100%', display: 'flex', marginRight: 20 }}>
                    <FormControl>
                        <SimpleTreeView expandedItems={expandedItemIds} onExpandedItemsChange={(event, itemIds) => setExpandedItemIds(itemIds.filter(id => !expandedItemIds.includes(id)))}>
                            <TreeItem itemId="1" label={<Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }}>Map Type</Typography>}>
                                <RadioGroup
                                    value={props.tilingLayer === undefined ? 'none' : props.tilingLayer}
                                    onChange={(event, layer) => props.setTilingLayer(layer === 'none' ? undefined : layer)}
                                >
                                    {['none', 'radar', 'temperature', 'rain_accumulation', 'snow_accumulation', 'wind_speed', 'wind_gust', 'road', 'disruption', 'flood', 'power', 'life_property', 'wildfire_spread'].map((value) => (
                                        <FormControlLabel
                                            key={value}
                                            value={value}
                                            control={<Radio size="small" />}
                                            label={<Typography variant="caption">{value === 'none' ? 'No Risk Layers' : formatRatingKey(value)}</Typography>}
                                            sx={{ my: -0.5 }}
                                        />
                                    ))}
                                </RadioGroup>
                            </TreeItem>
                            <TreeItem itemId="2" label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }} style={{ marginRight: 8 }}>
                                        Event Layers
                                    </Typography>
                                    {/* {(true || Object.values(props.itemCounts).some(c => c === undefined)) && <ThreeDot color="rgb(144, 202, 249)" style={{ fontSize: 6 }} />} */}
                                </div>
                            }>
                                <FormGroup>
                                    <EventLink
                                        label="Hurricanes"
                                        isChecked={props.isCyclonesVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onCyclonesToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['cyclones'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="Earthquakes"
                                        isChecked={props.isEarthquakesVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onEarthquakesToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['earthquakes'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="Lightning"
                                        isChecked={props.isLightningVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onLightningToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['lightning'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="Power Outages"
                                        isChecked={props.isPowerOutagesVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onPowerOutagesToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['powerOutages'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="Weather Events"
                                        isChecked={props.isStormsVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onStormsToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['storms'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="Storm Reports"
                                        isChecked={props.isStormReportsVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onStormReportsToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['stormReports'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="Volcanoes"
                                        isChecked={props.isVolcanoesVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onVolcanoesToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['volcanoes'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="Fires"
                                        isChecked={props.isFiresVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onFiresToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['fires'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    <EventLink
                                        label="US Wildfires"
                                        isChecked={props.isWildfiresVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onWildfiresToggled : setShowCriticalEventsUpsell}
                                        itemCount={props.hasEventsPermission ? props.itemCounts['wildfires'] : null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />
                                    {isValidUserForFeature(props.userData.id, { 'production': [ClientId.Production.AFB] }) && <EventLink
                                        label="Flood Chance"
                                        isChecked={props.isFloodChanceVisible}
                                        onToggled={props.userData.showEventsInPortal ? props.onFloodChanceToggled : setShowCriticalEventsUpsell}
                                        itemCount={null}
                                        isDisabled={!props.userData.showEventsInPortal}
                                    />}
                                </FormGroup>
                            </TreeItem>

                            <TreeItem itemId="3" label={<Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }}>Traffic Layers</Typography>}>
                                <RadioGroup
                                    value={props.trafficLayer === undefined ? 'none' : props.trafficLayer}
                                    onChange={(event, layer) => (props.userData.show511InPortal || layer === 'none' || layer === 'traffic') ? props.onTrafficLayerSelected(layer === 'none' ? undefined : layer.startsWith('traffic') ? layer : `traffic-${layer}`) : setShowRoadConditionsUpsell(true)}
                                >
                                    {['none', 'traffic', 'traffic-incidents', 'road-status', 'road-work', 'road-closures', 'truck-warnings'].map((value, index) => (
                                        <FormControlLabel
                                            key={value}
                                            value={value}
                                            control={<Radio size="small" />}
                                            label={<div style={{ display: 'flex' }}>
                                                <Typography variant="caption">{value === 'none' ? 'No Traffic Layers' : value.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</Typography>
                                                {(!props.userData.show511InPortal && index >= 2) && <img style={{ marginLeft: 5 }} alt={'premium-feature'} src={'/images/premium_feature_gem.svg'} />}
                                            </div>}
                                            sx={{ my: -0.5 }}
                                        />
                                    ))}
                                </RadioGroup>
                            </TreeItem>

                            <TreeItem itemId="4" label={<Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }}>Gov Layers</Typography>}>
                                <FormGroup>
                                    <FormControlLabel className={'checkbox'} control={<Checkbox sx={{ my: -0.5 }} size="small" checked={props.isAlertsVisible} onChange={event => props.onAlertsToggled(event.target.checked)} />} label={<Typography variant="caption">NWS Alerts</Typography>} />
                                </FormGroup>
                            </TreeItem>
                        </SimpleTreeView>
                    </FormControl>
                </div>
            </div>
        </div>
    );

    const closeButton = (
        <IconButton
            color={'primary'}
            size={'small'}
            onClick={() => setMenuCategory('top-menu')}
        >
            <Close fontSize='small' fontWeight={'bold'} />
        </IconButton>
    );

    const filterCloseButton = (
        <IconButton
            color={'primary'}
            size={'small'}
            onClick={() => setMenuCategory('asset-types')}
        >
            <Close fontSize='small' fontWeight={'bold'} />
        </IconButton>
    );

    return (
        <div className={`NowcastMenu-Container ${props.isGraphExpanded ? 'graph-expanded' : ''}`}>
            <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
                overflow: menuCategory === 'asset-types' ? 'hidden' : 'auto'
            }}>
                <MenuCategoryButtons
                    category={menuCategory}
                    selectedAssetGroup={props.selectedAssetGroup}
                    onCategorySelected={category => setMenuCategory(category)}
                    onSuperSearchPressed={props.onSuperSearchPressed}
                />
            </Paper>

            {menuCategory === 'risk-layers' && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                paddingLeft: 20,
                paddingTop: 8,
                paddingRight: 20,
                width: 200,
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>{menuHeadings.mapLayers}</Typography>
                    <Box flexGrow={1} />
                    {closeButton}
                </header>
                <Box height={10} />
                <div className="content"> {/* content is scrollable */}
                    {mapLayerOptions}
                    <Box height={20} />
                </div>
            </Paper>}

            {(menuCategory.startsWith('asset') || menuCategory.startsWith('filter')) && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
                width: 190,
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>Show Assets on Map</Typography>
                    <Box flexGrow={1} />
                    {menuCategory === 'asset-types' && closeButton}
                </header>
                <Box height={10} />

                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 12, paddingBottom: 12 }}>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        style={{ marginBottom: 8, paddingLeft: 16, paddingRight: 16, color: '#FFFFFF', borderColor: '#FFFFFF' }}

                        onClick={() => props.setSelectedAssetGroup('location')}
                        href="javascript:;"
                    >
                        &nbsp;
                        <RoomOutlined style={{ marginRight: '5px' }} fontSize={'small'} />
                        locations
                        <Box flexGrow={1} />
                        {props.selectedAssetGroup === 'location' ? <RadioButtonCheckedOutlined fontSize={'small'} /> : <RadioButtonUncheckedOutlined fontSize={'small'} />}
                    </Button>

                    <Button
                        variant={'outlined'}
                        size={'small'}
                        style={{ marginBottom: 8, paddingLeft: 16, paddingRight: 16, color: '#FFFFFF', borderColor: '#FFFFFF' }}

                        onClick={() => props.setSelectedAssetGroup('vehicle')}
                        href="javascript:;"
                    >
                        &nbsp;
                        <DriveEtaOutlined style={{ marginRight: '5px' }} fontSize={'small'} />
                        vehicles
                        <Box flexGrow={1} />
                        {props.selectedAssetGroup === 'vehicle' ? <RadioButtonCheckedOutlined fontSize={'small'} /> : <RadioButtonUncheckedOutlined fontSize={'small'} />}
                    </Button>

                    <Button
                        variant={'outlined'}
                        size={'small'}
                        style={{ marginBottom: 8, paddingLeft: 16, paddingRight: 16, color: '#FFFFFF', borderColor: '#FFFFFF' }}

                        onClick={() => props.setSelectedAssetGroup('route')}
                        href="javascript:;"
                    >
                        &nbsp;
                        <RouteOutlined style={{ marginRight: '5px' }} fontSize={'small'} />
                        shipments
                        <Box flexGrow={1} />
                        {props.selectedAssetGroup === 'route' ? <RadioButtonCheckedOutlined fontSize={'small'} /> : <RadioButtonUncheckedOutlined fontSize={'small'} />}
                    </Button>

                    <Box height={10} />

                    <Button
                        variant={menuCategory === 'filter-asset-groups' ? 'contained' : 'outlined'}
                        color={'primary'}
                        size={'small'}
                        style={{ marginBottom: 12, paddingLeft: 8, paddingRight: 8 }}

                        onClick={() => menuCategory === 'filter-asset-groups' ? setMenuCategory('asset-types') : setMenuCategory('filter-asset-groups')}
                        href="javascript:;"
                    >
                        &nbsp;
                        <img style={{ marginRight: '5px' }} src={'/images/aggregate.svg'} height={16} width={16} />
                        Show/Hide Groups
                    </Button>

                    <Button
                        variant={menuCategory === 'filter-by-impact' ? 'contained' : 'outlined'}
                        color={'primary'}
                        size={'small'}
                        style={{ marginBottom: 12, paddingLeft: 12, paddingRight: 12 }}

                        onClick={() => menuCategory === 'filter-by-impact' ? setMenuCategory('asset-types') : setMenuCategory('filter-by-impact')}
                        href="javascript:;"
                    >
                        &nbsp;
                        <FilterList style={{ marginRight: '5px' }} fontSize={'small'} />
                        Filter By impact
                    </Button>

                    <Button
                        variant={menuCategory === 'asset-list' ? 'contained' : 'outlined'}
                        color={'primary'}
                        size={'small'}
                        style={{ marginBottom: 12, paddingLeft: 12, paddingRight: 12 }}

                        onClick={() => menuCategory === 'asset-list' ? setMenuCategory('asset-types') : setMenuCategory('asset-list')}
                        href="javascript:;"
                    >
                        &nbsp;
                        <List style={{ marginRight: '5px' }} fontSize={'small'} />
                        {props.selectedAssetGroup === 'location' ? 'Location' : props.selectedAssetGroup === 'vehicle' ? 'Vehicle' : 'Shipment'} List
                    </Button>

                    {/* <Button
                        variant={menuCategory === 'asset-list' ? 'contained' : 'outlined'}
                        color={'primary'}
                        size={'small'}
                        style={{ marginBottom: 12, paddingLeft: 12, paddingRight: 12 }}

                        onClick={() => props.onSuperSearchPressed()}
                        href="javascript:;"
                    >
                        &nbsp;
                        <Search style={{ marginRight: '5px' }} fontSize={'small'} />
                        Search Assets
                    </Button> */}
                </div>

            </Paper>}

            {menuCategory === 'filter-asset-groups' && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
                width: 200,
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>Show/Hide Groups</Typography>
                    <Box flexGrow={1} />
                    {filterCloseButton}
                </header>
                <Box height={10} />

                {props.selectedAssetGroup === 'location' && <div>
                    <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: 12 }}>
                        <Typography variant={'caption'} color={'textSecondary'} style={{ marginRight: 8 }}>Select:</Typography>
                        <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onLocationSelectedTypesChanged(props.locationAssetTypeOptions)}>ALL</Button>
                        <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onLocationSelectedTypesChanged([])}>NONE</Button>
                        <Box flexGrow={1} />
                    </Box>

                    <FormControl size="small" className="content" sx={{ width: '100%' }}>
                        {props.locationAssetTypeOptions.map(assetType => (
                            <FormControlLabel
                                key={assetType.label}
                                value={assetType.category}
                                control={<Checkbox size="small" checked={props.locationSelectedAssetTypes.find(at => at.category === assetType.category) !== undefined} />}
                                label={
                                    <Typography variant="caption">
                                        {assetType.label}
                                        {props.locationCountsByAssetCategory[assetType.category || ''] !== undefined ? ` (${props.locationCountsByAssetCategory[assetType.category || '']})` : ''}
                                    </Typography>
                                }
                                sx={{ my: -0.5 }} // less vertical spacing between checkboxes
                                onChange={(event, checked) => {
                                    if (checked) {
                                        props.onLocationSelectedTypesChanged([...props.locationSelectedAssetTypes, assetType]);
                                    } else {
                                        props.onLocationSelectedTypesChanged(props.locationSelectedAssetTypes.filter(t => t !== assetType));
                                    }
                                }}
                            />
                        ))}
                    </FormControl>
                </div>}

                {props.selectedAssetGroup === 'vehicle' && <div>
                    <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: 12 }}>
                        <Typography variant={'caption'} color={'textSecondary'} style={{ marginRight: 8 }}>Select:</Typography>
                        <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onVehicleSelectedTypesChanged(props.vehicleAssetTypeOptions)}>ALL</Button>
                        <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onVehicleSelectedTypesChanged([])}>NONE</Button>
                        <Box flexGrow={1} />
                    </Box>


                    <FormControl size="small" className="content" sx={{ width: '100%', maxHeight: '300px' }}>
                        {props.vehicleAssetTypeOptions.map(assetType => (
                            <FormControlLabel
                                key={assetType.label}
                                value={assetType.category}
                                control={<Checkbox size="small" checked={props.vehicleSelectedAssetTypes.includes(assetType)} />}
                                label={
                                    <Typography variant="caption">
                                        {assetType.label}
                                        {props.vehicleCountsByAssetCategory[assetType.category || ''] !== undefined ? ` (${props.vehicleCountsByAssetCategory[assetType.category || '']})` : ''}
                                    </Typography>
                                }
                                sx={{ my: -0.5 }} // less vertical spacing between checkboxes
                                onChange={(event, checked) => {
                                    if (checked) {
                                        props.onVehicleSelectedTypesChanged([...props.vehicleSelectedAssetTypes, assetType]);
                                    } else {
                                        props.onVehicleSelectedTypesChanged(props.vehicleSelectedAssetTypes.filter(t => t !== assetType));
                                    }
                                }}
                            />
                        ))}
                    </FormControl>
                </div>}

                {props.selectedAssetGroup === 'route' && <div>
                    <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: 12 }}>
                        <Typography variant={'caption'} color={'textSecondary'} style={{ marginRight: 8 }}>Select:</Typography>
                        <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onShipmentSelectedTypesChanged(props.shipmentAssetTypeOptions)}>ALL</Button>
                        <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onShipmentSelectedTypesChanged([])}>NONE</Button>
                        <Box flexGrow={1} />
                    </Box>


                    <FormControl size="small" className="content" sx={{ width: '100%' }}>
                        {props.shipmentAssetTypeOptions.map(assetType => (
                            <FormControlLabel
                                key={assetType.label}
                                value={assetType.category}
                                control={<Checkbox size="small" checked={props.shipmentSelectedAssetTypes.includes(assetType)} />}
                                label={
                                    <Typography variant="caption">
                                        {assetType.label}
                                        {props.shipmentCountsByAssetCategory[assetType.category || ''] !== undefined ? ` (${props.shipmentCountsByAssetCategory[assetType.category || '']})` : ''}
                                    </Typography>
                                }
                                sx={{ my: -0.5 }} // less vertical spacing between checkboxes
                                onChange={(event, checked) => {
                                    if (checked) {
                                        props.onShipmentSelectedTypesChanged([...props.shipmentSelectedAssetTypes, assetType]);
                                    } else {
                                        props.onShipmentSelectedTypesChanged(props.shipmentSelectedAssetTypes.filter(t => t !== assetType));
                                    }
                                }}
                            />
                        ))}
                    </FormControl>
                </div>}
            </Paper>}

            {menuCategory === 'filter-by-impact' && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
                width: 200,
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>Filter By Impacts</Typography>
                    <Box flexGrow={1} />
                    {filterCloseButton}
                </header>
                <Box height={10} />
                <div className="content"> {/* content is scrollable */}
                    <SingleFilterSelector
                        filter={props.filters[0] || { variable: null, operator: null, value: null }}
                        assetAlerts={props.assetAlerts}
                        assetRisks={props.selectedAssetGroup === 'route' ?
                            ['Snow', 'Rain', 'Freezing Rain', 'Wind', 'Visibility', 'Extreme Weather'] :
                            [
                                'Moderate Rain', 'Heavy Rain', 'Violent Rain',
                                'Moderate Wind', 'Strong Wind', 'Severe Wind',
                                'Low Road Danger', 'Moderate Road Danger', 'High Road Danger',
                                'Low Business Disruption', 'Moderate Business Disruption', 'High Business Disruption',
                                'Low Flood Danger', 'Moderate Flood Danger', 'High Flood Danger',
                                'Low Life & Property Danger', 'Moderate Life & Property Danger', 'High Life & Property Danger',

                            ]}
                        onFilterChanged={filter => props.onFiltersChanged([filter])}
                    />
                </div>
            </Paper>}

            {menuCategory === 'asset-list' && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
                width: 250,
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>Export {props.selectedAssetGroup === 'location' ? 'Location' : props.selectedAssetGroup === 'vehicle' ? 'Vehicle' : 'Shipment'}s</Typography>
                    <Box flexGrow={1} />
                    {filterCloseButton}
                </header>
                <Box height={10} />
                <div>
                    {props.selectedAssetGroup !== 'route' && <FormControlLabel
                        className={'checkbox'}
                        control={<Checkbox
                            sx={{ my: -0.5 }}
                            size="small"
                            checked={filterAssetsToMapViewport}
                            onChange={() => setFilterAssetsToMapViewport(!filterAssetsToMapViewport)} />}
                        label="Filter to Current View" />}
                    <div className="content" style={{ maxHeight: "300px" }}>
                        {selectedAssets.length > 0 ? selectedAssets.map(asset => (
                            <div>
                                <Chip
                                    style={{
                                        margin: "5px 15px 5px 15px",
                                        padding: 8,
                                        backgroundColor: darkTagColorForImpactLevel(getAssetImpactLevel(asset, props.selectedTimeframeIndex)),
                                        color: 'white',
                                        fontWeight: 'bold',
                                        maxWidth: 250
                                    }}
                                    label={getAssetName(asset)}
                                    onClick={() => props.onAssetSelected(asset)}
                                />
                            </div>
                        )) : <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>No Assets</Typography>}
                    </div>
                    <Button
                        disabled={selectedAssets.length === 0}
                        onClick={() => {
                            exportCSVFile(
                                selectedAssets.map(asset => {
                                    if (props.selectedAssetGroup === 'location') {
                                        return {
                                            Location: getAssetName(asset),
                                            Latitude: (asset as LocationData).latitude,
                                            Longitude: (asset as LocationData).longitude,
                                            'Max Impact Level': props.selectedTimeframeIndex === 0 ? wordForImpactLevel((asset as LocationData).subhourlyImpactSummary?.impactLevel ?? ImpactLevel.Unknown) : wordForImpactLevel((asset as LocationData).extendedImpactSummary?.impactLevel ?? ImpactLevel.Unknown),
                                            'Specific Risks': props.selectedTimeframeIndex === 0 ? (asset as LocationData).subhourlyImpactSummary?.tags.map(tag => tag.text).join("|") : (asset as LocationData).extendedImpactSummary?.tags.map(tag => tag.text).join("|"),
                                        };
                                    } else if (props.selectedAssetGroup === 'vehicle') {
                                        return {
                                            Vehicle: getAssetName(asset),
                                            Latitude: (asset as VehicleTrackingData).latitude,
                                            Longitude: (asset as VehicleTrackingData).longitude,
                                            'Current Impact Level': wordForImpactLevel((asset as VehicleTrackingData).currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown),
                                            'Specific Risks': (asset as VehicleTrackingData).currentImpact?.tags.map(tag => tag.text.split(',')).flat().join("|"),
                                        };
                                    } else {
                                        return {
                                            Shipment: getAssetName(asset),
                                            'Origin Latitude': (asset as RouteData).origin.latitude,
                                            'Origin Longitude': (asset as RouteData).origin.longitude,
                                            'Destination Latitude': (asset as RouteData).destination.latitude,
                                            'Destination Longitude': (asset as RouteData).destination.longitude,
                                            'Max Impact Level': wordForImpactLevel(((asset as RouteData).latestRouteResults !== undefined && (asset as RouteData).latestRouteResults!.length > 0) ? findImpactLevel((asset as RouteData).latestRouteResults![0]) : ImpactLevel.Unknown),
                                            'Max Road Risk': ((asset as RouteData).latestRouteResults !== undefined && (asset as RouteData).latestRouteResults!.length > 0) ? (asset as RouteData).latestRouteResults![0].maxRoadIndex : undefined,
                                            'Specific Risks': ((asset as RouteData).latestRouteResults !== undefined && (asset as RouteData).latestRouteResults!.length > 0) ? (asset as RouteData).latestRouteResults![0].weatherFlags.join("|").replaceAll('_flag', '').replaceAll('_', ' ') : undefined,
                                        };
                                    }
                                }),
                                props.selectedAssetGroup === 'location' ? 'Locations' : props.selectedAssetGroup === 'vehicle' ? 'Vehicles' : 'Shipments'
                            );
                        }}
                        href="javascript:;"
                    >
                        &nbsp;
                        <ExportIcon style={{ marginRight: '5px' }} fontSize={'small'} />
                        Export
                    </Button>
                    {/* TODO: do we want to allow mass alerts like this?
                        <Button
                        href="javascript:;"
                    >
                        &nbsp;
                        <Announcement style={{ marginRight: '5px' }} fontSize={'small'} />
                        Send Alert
                    </Button> */}
                </div>
            </Paper>}

            <Dialog open={showCriticalEventsUpsell || showRoadConditionsUpsell}>
                <DialogTitle>
                    New Product: {showCriticalEventsUpsell ? 'Critical Events!' : '511 Road Conditions!'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showCriticalEventsUpsell ? 'Critical Events help your team monitor natural disasters for improved enterprise resiliency & planning. To learn more and upgrade to this product, contact your account manager.' :
                            '511 Road Conditions help your team monitor real time traffic conditions, road status and road work for improved enterprise resiliency & planning. To learn more and upgrade to this product, contact your account manager.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={showCriticalEventsUpsell ? () => setShowCriticalEventsUpsell(false) : () => setShowRoadConditionsUpsell(false)}>Got it!</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default NowcastMenu;