import {
    calculateImpactScore,
    emojiForImpactLevel,
    findImpactLevel,
    findRouteImpactReasons,
    getFormattedDepartureTime,
    RouteData,
    RouteResults,
    wordForImpactLevel
} from '../../../types/routes';
import * as React from "react";
import {
    DataGridPro,
    DataGridProProps,
    getGridStringOperators,
    GridAddIcon,
    GridColumns,
    GridColumnVisibilityModel,
    gridFilteredDescendantCountLookupSelector,
    gridFilteredSortedRowIdsSelector,
    GridFilterModel,
    GridLinkOperator,
    GridRemoveIcon,
    GridRenderCellParams,
    GridSortModel,
    GridValueFormatterParams,
    GridValueGetterParams,
    useGridApiRef,
    useGridSelector
} from "@mui/x-data-grid-pro";
import { Box, ButtonProps, CircularProgress, IconButton, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { CustomToolbar } from "./ImpactDetailView";
import TimeAgo from "javascript-time-ago";
import { LocalShippingOutlined, RefreshOutlined, Route, ThumbUpOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { API_HOST } from 'src/constants';
import { TablePaginationActions } from 'src/components/shared/TablePaginationActions';
import { BlurbsByIndex, LoadableResultMetadata, VehicleTrackingData } from 'src/types';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { getUserDescriptionOfError } from 'src/types/unmarshal';
import { findMatchingVehicleForRoute } from './data';
import { Config } from 'src/components/shared/useConfig';

interface RouteTableData {
    id: string;
    hierarchy: string[];
    routeResultIndex?: number;

    route: RouteData;
}

interface Props {
    token: string | undefined;
    routes: RouteData[];
    routesMetadata: LoadableResultMetadata;
    vehicles: VehicleTrackingData[];
    vehiclesMetadata: LoadableResultMetadata;
    refreshingRouteIds: number[];
    selectedRoute?: RouteData;
    selectedView?: string;
    searchQuery: string;
    blurbs: BlurbsByIndex;

    pageSize?: number;
    onRouteSelected: (route: RouteData) => void;
    onRouteRefreshRequested: (route: RouteData) => void;
    onVehicleSelected: (vehicle: VehicleTrackingData) => void;
}

export const ImpactedRoutesTable = (props: Props) => {
    const { token, routes, routesMetadata, vehicles, vehiclesMetadata, refreshingRouteIds, selectedRoute, selectedView, blurbs, onVehicleSelected } = props;
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'impactScore', sort: 'desc' }]);
    const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        id: false,
        externalId: true,
        name: true,
        origin: true,
        destination: true,
        departureTime: true,
        impactScore: true,
        slowdownFraction: true,
        adjustedArrivalTime: true,
        distance: true,
        maxRoadIndex: true,
        weatherFlags: true,
        aboveTemeratureThresholds: true,
        belowTemeratureThresholds: true,
        status: true,
        routeResultsUpdatedAt: true,
        actions: true
    });
    const apiRef = useGridApiRef();
    const history = useHistory();
    const prevTableState = React.useRef<GridInitialStatePro>();
    const shouldShowRouteVehiclePairs = Config.getBoolean(Config.Key.ShowRouteVehiclePairs);

    let routesTree: RouteTableData[] = [];

    let initialTableState: GridInitialStatePro | undefined = undefined;
    let initialState = window.localStorage.getItem('routesTableState');
    if (initialState) {
        initialTableState = JSON.parse(initialState) as GridInitialStatePro;
    }

    // the column visibility model does not export with the rest of table state until MUI 6+
    // we handle it by storing it separately https://github.com/mui/mui-x/issues/6589
    const columnVisibilityModelKey = 'columnVisibilityModel';
    React.useEffect(() => {
        const colVisModelString = window.localStorage.getItem(columnVisibilityModelKey);
        if (colVisModelString !== null) {
            const colVisModel = JSON.parse(colVisModelString);
            const colVisModelKeys = Object.keys(colVisModel);
            const missingKeys = Object.keys(columnVisibilityModel).filter(k => !colVisModelKeys.includes(k));
            console.log(`adding default values for missing keys in visibility model: ${missingKeys}`);
            for (const key of missingKeys) {
                colVisModel[key] = columnVisibilityModel[key];
            }
            setColumnVisibilityModel(colVisModel);
        }
    }, []);

    React.useEffect(() => {
        window.localStorage.setItem(columnVisibilityModelKey, JSON.stringify(columnVisibilityModel));
    }, [columnVisibilityModel]);

    React.useEffect(() => {
        if (selectedRoute !== undefined && selectedRoute.id !== undefined) {
            // hack to expand row if map polyline is clicked
            if (selectedRoute.selectedRouteOption !== undefined && selectedRoute.selectedRouteOption.indexOf('polyline') > -1) {
                selectedRoute.selectedRouteOption = selectedRoute.selectedRouteOption.substring(0, selectedRoute.selectedRouteOption.indexOf('polyline')).trim();
                apiRef.current.setRowChildrenExpansion(selectedRoute.id, true);
            }
            selectRouteAndOption(selectedRoute);
        }
    }, [selectedRoute, selectedView]);

    const selectRouteAndOption = (route: RouteData) => {
        let rows = [];
        if (route !== undefined && route.id !== undefined) {
            // collapse last selected row (unless selectign a different option in same row)
            if (selectedRows.length > 0 && route.id.toString() !== selectedRows[0]) {
                selectedRows.forEach(rowId => {
                    // ensure the row is in the current table or calling set expansion will crash
                    if (apiRef.current.getRowNode(rowId)) {
                        apiRef.current.setRowChildrenExpansion(rowId, false);
                    }
                });
            }

            // select rows - parent row and child option (if it is not option 1)
            rows.push(route.id.toString());
            if (route.selectedRouteOption !== undefined && route.selectedRouteOption.indexOf('Option 1') === -1) {
                rows.push(route?.selectedRouteOption);
            }
        }
        setSelectedRows(rows);
    };

    routes.forEach((route) => {
        // add parent and child routes
        if (route.latestRouteResults !== undefined) {
            route.latestRouteResults.forEach((routeResult, i) => {
                if (i === 0) {
                    // first child route option is used for parent data 
                    routesTree.push({
                        id: `${route.id}`,
                        hierarchy: [`${route.id}`],
                        routeResultIndex: i,
                        route,
                    });
                } else {
                    // add child routes
                    routesTree.push({
                        id: `${route.id} Option ${i + 1}`,
                        hierarchy: [`${route.id}`, `Option ${i + 1}`],
                        routeResultIndex: i,
                        route,
                    });
                }
            });
        }
        else {
            routesTree.push({
                id: `${route.id}`,
                hierarchy: [`${route.id}`],
                route,
            });
        }
    });

    const columnDependencies: any[] = [refreshingRouteIds, routes];
    if (Config.getBoolean(Config.Key.ShowRouteVehiclePairs)) {
        // when we want to show pairs, we need to recompute the columns
        // when the number of vehicles changes as we might find a new pair as a result
        columnDependencies.push(vehicles.length);
    }
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
    // columns are memoized to avoid infinite render glitch when passing sortModel
    // https://codesandbox.io/s/infinite-render-sortmodel-forked-bwv2g?file=/src/Demo.tsx:1528-1575
    const columns = React.useMemo<GridColumns>(() => [{
        field: 'id',
        hide: true
    }, {
        field: 'externalId',
        headerName: 'External ID',
        filterOperators: stringOperators,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.route.externalId;
        },
    }, {
        field: 'name',
        headerName: 'Name',
        filterOperators: stringOperators,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.route.name;
        },
    }, {
        field: 'origin',
        headerName: 'Origin',
        minWidth: 100,
        filterOperators: stringOperators,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.id.indexOf('Option') > -1) return '';
            return params.row.route.origin.label;
        }
    }, {
        field: 'destination',
        headerName: 'Destination',
        minWidth: 100,
        filterOperators: stringOperators,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.id.indexOf('Option') > -1) return '';
            return params.row.route.destination.label;
        }
    }, {
        field: 'departureTime',
        headerName: 'Departure Time',
        minWidth: 140,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.id.indexOf('Option') > -1) return params.row.id.substring(params.row.id.indexOf('Option'));
            return params.row.route.departureTime;
        },
        valueFormatter: (params: GridValueFormatterParams) => {
            if (params.value === undefined) return '';
            if (typeof params.value === 'string') {
                const value = params.value as string;
                // convert route number to letter
                if (value.indexOf('Option') > -1) return `Option ${String.fromCharCode(parseInt(value.split(' ')[1]) - 1 + 'A'.charCodeAt(0))}`;
            }
            return getFormattedDepartureTime(params.value as Date);
        }
    }, {
        field: 'impactScore',
        headerName: 'Impact',
        minWidth: 95,
        // disableColumnMenu: true,
        sortComparator: (r1: RouteResults[] | undefined, r2: RouteResults[] | undefined) => {
            if (r1 === undefined) return -1;
            if (r2 === undefined) return 1;

            return calculateImpactScore(r1[0]) - calculateImpactScore(r2[0]);
        },
        valueGetter: (params: GridValueGetterParams) => {
            const results = params.row.route.latestRouteResults;

            if (results === undefined) {
                return undefined;
            }

            return [results[params.row.routeResultIndex!]];
        },
        valueFormatter: (params) => {
            const results = params.value as RouteResults[];
            if (results === undefined) {
                return '';
            }
            // return words for csv export
            return wordForImpactLevel(findImpactLevel(results[0]));
        },
        renderCell: (params) => {
            if (params.row.route.latestRouteResults === undefined) {
                if (params.row.route.latestRouteResultError) {
                    const { errorTitle, errorMessage } = getUserDescriptionOfError(params.row.route.latestRouteResultError.error);
                    return (
                        <Tooltip title={`${errorTitle}: ${errorMessage}`}>
                            <div>
                                {"Error ⚠️"}
                            </div>
                        </Tooltip>
                    );
                }
                return 'Updating ⏲️';
            }

            const routeResult: RouteResults = params.row.route.latestRouteResults[params.row.routeResultIndex!];
            const impactLevel = findImpactLevel(routeResult);
            const emoji = emojiForImpactLevel(impactLevel);
            const text = `${params.formattedValue} ${emoji}`;
            const reasons = findRouteImpactReasons(routeResult);

            if (reasons.length > 0) {
                return (
                    <Tooltip title={reasons.map(reason => <div>{reason}</div>)}>
                        <div>
                            {text}
                        </div>
                    </Tooltip>
                );
            } else {
                return text;
            }
        }
    }, {
        field: 'slowdownFraction',
        headerName: 'Slowdown',
        minWidth: 90,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.route.latestRouteResults === undefined) return '';
            return params.row.route.latestRouteResults[params.row.routeResultIndex!].slowdownFraction;
        },
        valueFormatter: (params: GridValueFormatterParams) => {
            if (params.value === undefined) {
                return '';
            }

            const percentage = params.value as number * 100;
            return `${Math.round(percentage)}%`;
        }
    }, {
        field: 'adjustedArrivalTime',
        headerName: 'Adjusted ETA',
        minWidth: 140,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.route.latestRouteResults === undefined) {
                return undefined;
            }

            const results = params.row.route.latestRouteResults[params.row.routeResultIndex!];

            if (results === undefined) {
                return undefined;
            }

            return results.adjustedArrivalTime;
        },
        valueFormatter: (params: GridValueFormatterParams) => getFormattedDepartureTime(params.value as Date),
        renderCell: (params) => {
            if (params.row.route.latestRouteResults === undefined) {
                return params.formattedValue;
            }
            const rowId = params.row.id;
            const optionNum: number = parseInt(rowId.split(' ')[2]);
            const route: RouteData = params.row.route;
            const minIndex = route.latestRouteResults!.indexOf(route.latestRouteResults!.slice().sort((a, b) => a.adjustedArrivalTime.getTime() - b.adjustedArrivalTime.getTime())[0]);

            // parent route grid cell with a better option than option 1
            if (rowId.indexOf('Option') === -1 && minIndex !== 0) {
                return (
                    <Tooltip title={'There is another route option with an earlier arrival time.'}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>{params.formattedValue} <WarningAmberOutlined style={{ color: 'yellow' }} /></div>
                    </Tooltip>

                );
            }
            // child route option grid cell and min value is not for option 1
            else if (minIndex !== 0 && optionNum === minIndex + 1) {
                return (
                    <Tooltip title={'This route option has the earliest arrival time.'}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>{params.formattedValue} <ThumbUpOutlined style={{ color: 'green' }} /></div>
                    </Tooltip>
                );
            }
            return params.formattedValue;
        }
    }, {
        field: 'distance',
        headerName: 'Distance',
        minWidth: 80,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.route.latestRouteResults === undefined) {
                return undefined;
            }

            const results = params.row.route.latestRouteResults[params.row.routeResultIndex!];

            if (results === undefined) {
                return undefined;
            }

            return results.distanceMiles;
        },
        valueFormatter: (params: GridValueFormatterParams) => params.value !== undefined ? `${Math.ceil(params.value)} mi` : undefined,
        renderCell: (params) => {
            return params.formattedValue;
        }
    }, {
        field: 'maxRoadIndex',
        headerName: 'Max Road Risk',
        minWidth: 120,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.route.latestRouteResults === undefined) return '';
            return params.row.route.latestRouteResults[params.row.routeResultIndex!].maxRoadIndex;
        },
        valueFormatter: (params: GridValueFormatterParams) => {
            if (params.value === undefined || params.value === '') {
                return '';
            }
            return params.value.toFixed(1);
        },
        renderCell: (params) => {
            if (params.row.route.latestRouteResults === undefined) {
                return params.formattedValue;
            }
            const rowId = params.row.id;
            const optionNum: number = parseInt(rowId.split(' ')[2]);
            const route: RouteData = params.row.route;
            const minIndex = route.latestRouteResults!.indexOf(route.latestRouteResults!.slice().sort((a, b) => a.maxRoadIndex - b.maxRoadIndex)[0]);

            // parent route grid cell with a better option than option 1
            if (rowId.indexOf('Option') === -1 && minIndex !== 0) {
                return (<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Tooltip title={blurbs.road?.at(Math.floor(params.value))?.blurb || 'Road Danger'}>
                        <span>{params.formattedValue}</span>
                    </Tooltip>
                    <Tooltip title={'There is another route option with a lower road risk.'}>
                        <WarningAmberOutlined style={{ color: 'yellow' }} />
                    </Tooltip>
                </div>);
            }
            // child route option grid cell and min value is not for option 1
            else if (minIndex !== 0 && optionNum === minIndex + 1) {
                return (<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Tooltip title={blurbs.road?.at(Math.floor(params.value))?.blurb || 'Road Danger'}>
                        <span>{params.formattedValue}</span>
                    </Tooltip>
                    <Tooltip title={'This route option has the lowest road risk.'}>
                        <WarningAmberOutlined style={{ color: 'yellow' }} />
                    </Tooltip>
                </div>);
            }
            return (<Tooltip title={blurbs.road?.at(Math.floor(params.value))?.blurb || 'Road Danger'}>
                <span>{params.formattedValue}</span>
            </Tooltip>);
        }
    }, {
        field: 'weatherFlags',
        headerName: 'Weather Conditions',
        minWidth: 150,
        filterOperators: stringOperators,
        // disableColumnMenu: true,
        sortComparator: (r1: RouteResults | undefined, r2: RouteResults | undefined) => {
            if (r1 === undefined) return -1;
            if (r2 === undefined) return 1;

            return r1.weatherFlags.length - r2.weatherFlags.length;
        },
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.route.latestRouteResults === undefined) return '';
            return params.row.route.latestRouteResults[params.row.routeResultIndex!];
        },
        valueFormatter: (params: GridValueFormatterParams) => {
            const results = params.value as RouteResults | undefined;
            const flags = (results?.weatherFlags ?? []) as string[];
            return flags.map(flag => {
                return flag
                    .replace(/_flag/, '')
                    .replace('_', ' ')
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            }).join(', ');
        }
    }, {
        field: 'aboveTemperatureThresholds',
        headerName: 'Above Temperature',
        minWidth: 175,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.route.latestRouteResults === undefined) return '';
            return params.row.route.latestRouteResults[params.row.routeResultIndex!];
        },
        renderCell: (params: GridRenderCellParams) => {
            if (params.value === undefined || params.value === '') {
                return '';
            }
            const results = params.value as RouteResults | undefined;
            const tempThreshold = results?.aboveTemperatureThresholds[0];
            if (tempThreshold && tempThreshold.percentBreachingThreshold > 0) {
                const time = Math.round(tempThreshold.secondsBreachingThreshold / 60);
                const timeStr = time > 59 ? `${Math.floor(time / 60)}:${(time % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} hrs` : `${time} mins`;
                return (<div><span style={{ fontSize: "1.5em", verticalAlign: "middle" }}>🔥</span> {`${tempThreshold.threshold.replace("=", " ")}F, ${timeStr}`}</div>);
            }
            return '';
        }
    }, {
        field: 'belowTemperatureThresholds',
        headerName: 'Below Temperature',
        minWidth: 175,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.route.latestRouteResults === undefined) return '';
            return params.row.route.latestRouteResults[params.row.routeResultIndex!];
        },
        renderCell: (params: GridRenderCellParams) => {
            if (params.value === undefined || params.value === '') {
                return '';
            }
            const results = params.value as RouteResults | undefined;
            const tempThreshold = results?.belowTemperatureThresholds[0];
            if (tempThreshold && tempThreshold.percentBreachingThreshold > 0) {
                const time = Math.round(tempThreshold.secondsBreachingThreshold / 60);
                const timeStr = time > 59 ? `${Math.floor(time / 60)}:${(time % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} hrs` : `${time} mins`;
                return (<div><span style={{ fontSize: "2em", verticalAlign: "middle" }}>❄</span> {`${tempThreshold.threshold.replace("=", " ")}F, ${timeStr}`}</div>);
            }
            return '';
        }
    }, {
        field: 'status',
        headerName: 'Status',
        filterOperators: stringOperators,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.route.status;
        },
    }, {
        field: 'routeResultsUpdatedAt',
        headerName: 'Updated',
        minWidth: 80,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.id.indexOf('Option') > -1) return undefined;
            return params.row.route.routeResultsUpdatedAt;
        },
        valueFormatter: (params: GridValueFormatterParams) => {
            if (params.value === undefined) return '';
            const timeAgo = new TimeAgo('en-US');
            return params.value ? `${timeAgo.format(params.value as Date, 'mini')} ago` : '';
        }
    }, {
        field: 'vehicleData',
        headerName: `Vehicle${shouldShowRouteVehiclePairs ? '' : ' (Coming Soon)'}`,
        minWidth: 40,
        filterOperators: stringOperators,
        // disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            const pairedVehicle = findMatchingVehicleForRoute(params.row.route, routes, vehicles);
            if (pairedVehicle === undefined) return '';
            return pairedVehicle.name || pairedVehicle.externalId || pairedVehicle.id;
        },
        renderCell: (params: GridRenderCellParams) => {
            if (!shouldShowRouteVehiclePairs) return '';

            const pairedVehicle = findMatchingVehicleForRoute(params.row.route, routes, vehicles);
            if (pairedVehicle === undefined) return '';
            const tooltipText = pairedVehicle.name || pairedVehicle.externalId || pairedVehicle.id;
            const tooltip = (<React.Fragment>
                {<p>{tooltipText}</p>}
            </React.Fragment>);
            const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
                <Tooltip {...props} classes={{ popper: className }} />
            ))(() => ({
                [`& .${tooltipClasses.tooltip}`]: {
                    fontSize: 12,
                    backgroundColor: "dimgrey",
                },
            }));
            return (
                <HtmlTooltip title={tooltip} placement="top">
                    <IconButton onClick={() => onVehicleSelected(pairedVehicle)}>
                        {<LocalShippingOutlined />}
                    </IconButton>
                </HtmlTooltip>
            );
        }
    }, {
        // fake field name to prevent the header from being highlighted weirdly
        field: 'actions',
        headerName: 'Actions',
        minWidth: 80,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.id.indexOf('Option') > -1) return '';
            return params.row.route as RouteData;
        },
        renderCell: (params: GridRenderCellParams) => {
            let route = params.value as RouteData;
            if (route.id === undefined) {
                return <div></div>;
            }
            // RouteTableData uses string for id, need to convert back to number for RouteData so reducer comparisons work
            route.id = parseInt(params.row.id);
            if (refreshingRouteIds.indexOf(route.id) === -1) {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <IconButton style={{ padding: "8px 3px 8px 3px" }}
                            onClick={() => props.onRouteRefreshRequested(route)}
                        >
                            <RefreshOutlined />
                        </IconButton>
                        <IconButton style={{ padding: "8px 3px 8px 3px" }}
                            onClick={() => {
                                const cacheRouteData = async () => {
                                    const response = await fetch(`${API_HOST}/routes/${route.id}/cache?token=${token}`);
                                    const json = await response.json();

                                    if (json["success"]) {
                                        history.push(`/routes/${json["cache_key"]}`);
                                    }
                                    else {
                                        console.log("Error caching route: ", json["error"]);
                                    }
                                };

                                cacheRouteData();
                            }}
                        >
                            <Route />
                        </IconButton>
                    </div>
                );
            } else {
                return (
                    <CircularProgress size={24} sx={{ margin: 'auto' }} />
                );
            }
        }
    }], columnDependencies);

    const filterModel: GridFilterModel = {
        items: [{
            id: 1,
            columnField: 'externalId',
            operatorValue: 'contains',
            value: props.searchQuery
        }, {
            id: 1,
            columnField: 'origin',
            operatorValue: 'contains',
            value: props.searchQuery
        }, {
            id: 1,
            columnField: 'destination',
            operatorValue: 'contains',
            value: props.searchQuery
        }, {
            id: 1,
            columnField: 'weatherFlags',
            operatorValue: 'contains',
            value: props.searchQuery
        }, {
            id: 1,
            columnField: 'vehicleData',
            operatorValue: 'contains',
            value: props.searchQuery
        }],
        linkOperator: GridLinkOperator.Or
    };

    const handleRowClick = (rowId: string) => {
        // find the route to corespnds to the row clicked
        const routeId: number = rowId && rowId.includes('Option') ? parseInt(rowId.split(' ')[0]) : parseInt(rowId!);
        let route: RouteData = routes.filter(route => route.id === routeId)[0];
        // add selected option if an option row was clicked
        if (rowId.includes('Option')) {
            route = { ...route, selectedRouteOption: rowId };
        }
        // update the route selected in the redux store
        props.onRouteSelected(route);
    };

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

    const isNavigationKey = (key: string) => key === 'Home' || key === 'End' || key.indexOf('Arrow') === 0 || key.indexOf('Page') === 0 || key === ' ';
    const RoutesGroupingCell = (props: GridRenderCellParams) => {
        const { id, field, rowNode } = props;
        const filteredDescendantCountLookup = useGridSelector(
            apiRef,
            gridFilteredDescendantCountLookupSelector,
        );
        const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

        const handleKeyDown: ButtonProps['onKeyDown'] = (event) => {
            if (event.key === ' ') {
                event.stopPropagation();
            }
            if (isNavigationKey(event.key) && !event.shiftKey) {
                apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
            }
        };

        const handleClick: ButtonProps['onClick'] = (event) => {
            apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
            apiRef.current.setCellFocus(id, field);
            handleRowClick(rowNode.id.toString());
            event.stopPropagation();
        };

        return (
            <Box sx={{ ml: rowNode.depth * 1.5, width: 40 }}>
                <div>
                    {filteredDescendantCount > 0 ? (
                        <>
                            <IconButton onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={-1} size={'small'} aria-label={'see children'} component={'span'}>
                                {rowNode.childrenExpanded ? <GridRemoveIcon fontSize={'small'} /> : <GridAddIcon fontSize={'small'} />}
                            </IconButton>
                        </>
                    ) : (
                        <span></span>
                    )}
                </div>
            </Box>
        );
    };

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: '',
        width: 45,
        renderCell: (params) => <RoutesGroupingCell {...params} />
    };

    const [page, setPage] = React.useState(0);
    const [userChangedPage, setUserChangedPage] = React.useState(false);
    const pageSize = props.pageSize ?? 5;

    const prevSelectedRoute = React.useRef<RouteData | undefined>();

    React.useEffect(() => {
        if (userChangedPage) return;
        if (selectedRoute === undefined) return;
        if (routesMetadata.loading) return;
        if (vehiclesMetadata.loading) return;

        const api = apiRef.current;
        if (!api) return;

        // filter out children rows
        const ids = gridFilteredSortedRowIdsSelector(apiRef).filter((id: string) => !id.includes('Option'));
        const index = ids.findIndex(id => selectedRoute.id?.toString() === id);
        if (index === -1) return;

        const pageForSelectedRoute = Math.floor(index / pageSize);
        if (page !== pageForSelectedRoute) {
            // putting set page in set timeout to avoid null pointer when trying to set page on initial render
            // thie is a nkown and open bug for MUI datagrid https://github.com/mui/mui-x/issues/6411
            setTimeout(() => setPage(pageForSelectedRoute), 0);
        }

        prevSelectedRoute.current = selectedRoute;
    }, [routes, selectedRoute, props.searchQuery, apiRef, selectedView]);

    React.useEffect(() => {
        // reset this each time a new route is selected so that route stays in current page unless the user again changes the page
        setUserChangedPage(false);
    }, [selectedRoute]);

    return (
        <div style={{ width: '100%' }}>
            <DataGridPro
                style={{ overscrollBehavior: "contain" }}
                treeData
                apiRef={apiRef}
                columns={columns}
                rows={routesTree}
                page={page}
                pageSize={pageSize}
                onPageChange={(page) => {
                    setPage(page);
                    // as far as I can tell this is only called when the page buttons are clicked 
                    // ie it is not called when the page variable is changed by set page
                    setUserChangedPage(true);
                }}
                pagination
                autoHeight
                density={'compact'}
                filterModel={filterModel}
                sortModel={sortModel}
                onSortModelChange={(newValue) => setSortModel(newValue)}
                disableChildrenSorting={true}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                    newModel['id'] = false;
                    setColumnVisibilityModel(newModel);
                }}
                onRowClick={(row, event) => {
                    const route: RouteTableData = row.row;
                    route.id && handleRowClick(route.id);
                }}
                selectionModel={selectedRows}
                components={{ Toolbar: CustomToolbar(apiRef, 'routes', true) }}
                componentsProps={{
                    pagination: {
                        ActionsComponent: TablePaginationActions
                    },
                    basePopper: {
                        placement: "top-start",
                        sx: {
                            paddingBottom: '30px',
                            '.MuiDataGrid-panelHeader': { display: "none" },
                            '.MuiDataGrid-columnsPanel .MuiDataGrid-columnsPanelRow:nth-child(-n + 2)': { display: "none" }
                        }
                    }
                }}
                onStateChange={(e) => {
                    const current = apiRef.current.exportState();
                    if (JSON.stringify(prevTableState.current) !== JSON.stringify(current)) {
                        prevTableState.current = current as GridInitialStatePro;
                        window.localStorage.setItem('routesTableState', JSON.stringify(current));
                    }
                }}
                initialState={initialTableState}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
            />
        </div>
    );
};
